import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { LOADING, STOP_LOADING } from './slice';

export const getAssignment = (assignmentId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}assignment/:campusId/get-assignment/${assignmentId}`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCourseAssignments = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}assignment/:campusId/get-assignment-course/${session}/${courseId}`,
        );
        if (response.status === 200) {
            const { data } = response.data || {};
            return data || [];
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteAssignment = (assignmentId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(
            `${currentAPI}assignment/:campusId/delete-assignment/${assignmentId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
        return false;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createAssignment =
    (courseId, session, payload, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) {
            dispatch(LOADING());
        }
        try {
            const response = await axiosInstance.post(
                `${currentAPI}assignment/:campusId/create-assignment/${session}/${courseId}`,
                payload,
            );
            if (response.status === 200) {
                toast.success('Assignment created successfully!');
                return true;
            }
        } catch (error) {
            const errorMessage = error?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const editAssignment =
    (assignmentId, payload, useLoader = true) =>
    async (dispatch) => {
        if (useLoader) {
            dispatch(LOADING());
        }
        try {
            const response = await axiosInstance.put(
                `${currentAPI}assignment/:campusId/edit-assignment/${assignmentId}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getStudentAssignment = (assignmentId, studentId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}assignment/:campusId/${assignmentId}/student/${studentId}`,
        );
        if (response.status === 200) {
            const { data } = response || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createDraftAssignment = (courseId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}assignment/:campusId/draft/${courseId}`, payload);
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        const errorMessage =
            error?.response?.data?.Message || 'An error occurred while saving draft, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAssignmentDraft = (courseId) => async (dispatch) => {
    // dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}assignment/:campusId/getDraft/${courseId}`);
        if (response.status === 200) {
            const { draft } = response.data || {};
            return draft;
        }
    } catch (error) {
        // const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const extendDeadline = (assignmentId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/:campusId/edit-deadline/${assignmentId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const allotMark = (studentId, assignmentId, payload, version) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/:campusId/allot-mark/${studentId}/${assignmentId}/${version}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Assignment marked successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// export const getDraftById = (id) => async (dispatch) => {
//     dispatch(LOADING());
//     try {
//         const response = await axiosInstance.get(`${currentAPI}assignment/:campusId/draft/${id}`);
//         if (response.status === 200) {
//             const { data } = response.data || {};
//             return data || [];
//         }
//     } catch (error) {
//         const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
//         toast.error(errorMessage);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };

export const getAssignmentSpreadsheet = (session, courseId, assignmentId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}assignment/:campusId/assignment-spreadsheet/${session}/${courseId}/${assignmentId}`,
        );
        if (response.status === 200) {
            const { data } = response.data || {};
            return data || [];
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAssignmentScoresheet = (session, courseId, version) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}assignment/:campusId/assignment-scoresheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            const { data } = response.data || {};
            return data || [];
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createNewAssignmentVersion = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/:campusId/create-new-assignment-version/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('New version created successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const promoteAssignmentVersion = (courseId, session, latestVersion, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/:campusId/promote-assignment-to-latest-version/${courseId}/${session}/${latestVersion}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Version promoted successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendAssignmentToBroadsheet = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/:campusId/send-assignment-scoresheet-to-broadsheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('Scoresheet was sent to CA successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAssignmentVersions = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}assignment/:campusId/assignment-version-history/${courseId}/${session}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const publishAssignment = (assignmentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const payload = {
            status: 'Published',
        };
        const response = await axiosInstance.put(
            `${currentAPI}assignment/:campusId/update-status/${assignmentId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Assignment published successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const markStudentAssignment = (assignmentId, studentId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}assignment/:campusId/mark-assignment/${assignmentId}/${studentId}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Assignment marked successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
