import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    title: Yup.string().nullable().required('Required'),
    deadlineDate: Yup.string().nullable().required('Required'),
    deadlineTime: Yup.string().nullable().required('Required'),
    totalMarks: Yup.string().nullable().required('Required'),
    assignmentType: Yup.string().nullable().required('Required'),
    // testOption: Yup.string().nullable().required('Required'),
    continuousAssessment: Yup.string().nullable().required('Required'),
    description: Yup.string().nullable().required('Required'),
});

export const ComposeValidationSchema = Yup.object().shape({
    instructions: Yup.string().required('Instructions are required'),
    showMarkForEachQuestion: Yup.string().required('This field is required'),
    showResultImmediately: Yup.string(),
    questionType: Yup.string().required('Question type is required'),
    questions: Yup.array()
        .of(
            Yup.object().shape({
                questionText: Yup.string().required('Question text is required'),
                questionType: Yup.string().required('Question type is required'),
                // options: Yup.array()
                //     .of(Yup.string().required('Option is required'))
                //     .min(1, 'At least one option is required'),
                // correctAnswer: Yup.array()
                //     .of(Yup.string().required('Correct answer is required'))
                //     .min(1, 'At least one correct answer is required'),
                maxMarks: Yup.number().min(0, 'Marks must be 0 or greater').required('Required'),
            }),
        )
        .min(1, 'At least one question is required'),
});
