import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import { GoBack } from '../../components/go-back';
import { Loader } from '../../components/loader';
import { FlexCentredRow, FlexRowCentered } from '../../containers/ScreenContainers';
import { getStaffDetails } from '../../redux/staff/actions';

import Experience from './tabs/Experience';
import Overview from './tabs/OverviewTab';
import Payroll from './tabs/Payroll';
import Personal from './tabs/personal';
import Publication from './tabs/Publication';
import Uploads from './tabs/Uploads';

const ViewDetails = () => {
    const dispatch = useDispatch();

    const { staffBio, staffExp, staff, staffUploads, staffPub, payrollDetails } = useSelector((store) => store.staff);

    const [, /*tab*/ setTab] = useState([]);

    useEffect(() => {
        dispatch(getStaffDetails());
    }, [dispatch]);

    if (!staff) return <Loader />;

    return (
        <div>
            <FlexCentredRow className="mb-[3.2rem]">
                <GoBack title="Dashboard" subTitle="View Details" />
            </FlexCentredRow>

            <CTabs>
                <FlexRowCentered>
                    <CNav variant="tabs">
                        <CNavItem>
                            <CNavLink onClick={() => setTab(0)}>Overview</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink onClick={() => setTab(1)}>Personal</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink onClick={() => setTab(2)}>Experience</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink onClick={() => setTab(3)}>Publication</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink onClick={() => setTab(4)}>Uploads</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink onClick={() => setTab(5)}>Payroll</CNavLink>
                        </CNavItem>
                    </CNav>
                </FlexRowCentered>

                <CTabContent>
                    <CTabPane>
                        <Overview staff={staff} bio={staffBio} exp={staffExp} />
                    </CTabPane>
                    <CTabPane>
                        <Personal bio={staffBio} staff={staff} />
                    </CTabPane>
                    <CTabPane>
                        <Experience exp={staffExp} />
                    </CTabPane>
                    <CTabPane>
                        <Publication pub={staffPub} />
                    </CTabPane>
                    <CTabPane>
                        <Uploads upload={staffUploads} />
                    </CTabPane>
                    <CTabPane>
                        <Payroll payrollDetails={payrollDetails} />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default ViewDetails;
