import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { capitalizeFirstLetter, getDegreeInfo, getDegreeStructs } from '../../utils';

import {
    LOADING,
    STOP_LOADING,
    GET_STAFF,
    GET_STAFF_BIO,
    GET_STAFF_EXP,
    GET_STAFF_PUB,
    GET_STAFF_UPLOADS,
    GET_PAYROLL_DETAILS,
} from './slice';

export const getStaffDetails = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff/staff-details`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            const {
                checkStaff,
                checkBiodata,
                checkExperience,
                publication,
                publicationData,
                checkPublication,
                checkUploads,
            } = data || {};
            // const { personalInfo } = checkBiodata || {};

            const academicStructure = checkStaff.academicStructure;

            const { firstDegreeStruct, secondDegreeStruct, thirdDegreeStruct } = getDegreeStructs(academicStructure);
            const { firstDegree, secondDegree, thirdDegree } = getDegreeInfo(checkStaff, 'academicStructure');

            // const transformedRefeereeInfo = refereeInfo?.map((item) => {
            //     // Don't want the refereeOccupation key passed down
            //     const { refereeOccupation, ...otherValues } = item;
            //     return {
            //         ...otherValues,
            //         occupation: refereeOccupation || item?.occupation,
            //     };
            // });

            const transformedBiodata = {
                ...checkBiodata,
                personalInfo: {
                    ...(checkBiodata?.personalInfo || {}),
                    // nextOfKinName: nextOfKinInfo?.nextOfKin || personalInfo?.nextOfKinName || '',
                    //     nextOfKinAddress: nextOfKinInfo?.nextOfKinAddress || personalInfo?.nextOfKinAddress || '',
                    //     nextOfKinPhoneNumber:
                    //         nextOfKinInfo?.nextOfKinPhoneNumber || personalInfo?.nextOfKinPhoneNumber || '',
                    //     relationshipWithNextOfKin:
                    //         nextOfKinInfo?.nextOfKinRelationship || personalInfo?.relationshipWithNextOfKin || '',
                },
                // reference: [...(refereeInfo ? transformedRefeereeInfo : reference)],
            };

            const transformedStaffData = {
                ...checkStaff,
                firstDegreeStruct: capitalizeFirstLetter(firstDegreeStruct),
                secondDegreeStruct: capitalizeFirstLetter(secondDegreeStruct),
                thirdDegreeStruct: capitalizeFirstLetter(thirdDegreeStruct),
                firstDegree,
                secondDegree,
                thirdDegree,
            };

            dispatch(GET_STAFF(transformedStaffData));
            dispatch(GET_STAFF_BIO(transformedBiodata));
            dispatch(GET_STAFF_EXP(checkExperience));
            dispatch(GET_STAFF_PUB(checkPublication || publication?.uploadPublication || publicationData));
            dispatch(GET_STAFF_UPLOADS(checkUploads));
           
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createStaffBiodata = (payload, headers) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff/:campusId/biodata`, payload, headers);
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Bio data updated successfully';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createStaffExperience = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff/:campusId/experience`, payload);
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Staff data updated successfully';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createStaffPublication = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const headers = {
            'Content-Type': 'multi-part/form-data',
        };
        const response = await axiosInstance.patch(`${currentAPI}staff/:campusId/publication`, payload, headers);

        if (response.status === 200 || response.status === 201) {
            const successMessage = response?.data?.data.message || 'Staff data created successfully';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createUploads = (payload, headers) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff/:campusId/certificates`, payload, headers);
        if (response.status === 200 || response.status === 201) {
            const successMessage = response?.data?.data.message || 'Staff data created successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getPayrollDetails = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff/:campusId/payrolls`);

        if (response.status === 200) {
            dispatch(GET_PAYROLL_DETAILS(response?.data.data));
            return response.data.data;
        }
    } catch (err) {
        const errorMessage = err?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createPayroll = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff/:campusId/payrolls`, payload);
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Payroll successfully saved!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editPayroll = (payload, id) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff/:campusId/payrolls/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Payroll successfully saved!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllBanksFromPaystack = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${process.env.REACT_APP_PAYSTACK_URL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
            },
        });
        const parsedResponse = await response.json();

        return parsedResponse?.data;
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
