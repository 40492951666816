import React, { useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import { FlexDiv, FlexRowSpaceBetween, RelativeContainer } from '../../../containers/ScreenContainers';
import { deleteAssignment, publishAssignment } from '../../../redux/assignment/actions';

import Assignments from './tabs/Assignments';
import Drafts from './tabs/Drafts';

const CONFIRM_TEXT = 'delete';

const CourseAssignments = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: courseId } = useParams();
    const { state } = useLocation();

    const { session, course } = state || {};
    const { isLoading } = useSelector((state) => state.assignment);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { courseCode, courseTitle } = course || {};
    const [openModal, setOpenModal] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [confirmInputValue, setConfirmInputValue] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [selectedTab, setSelectedTab] = useState('assignment');

    const handleInputChange = (e) => {
        setConfirmInputValue(e.target.value);
    };

    const handleCreate = () => navigate(`/assignments/create/${courseId}`, { state: { session, course } });

    const handleDelete = async () => {
        const res = await dispatch(deleteAssignment(selectedItem?._id));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setOpenModal('');
            setSelectedItem(null);
        }
    };

    const handlePublish = async () => {
        const res = await dispatch(publishAssignment(selectedItem?._id));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setOpenModal('');
            setSelectedItem(null);
        }
    };

    return (
        <div className="h-[100vh]">
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <GoBack title="Assignments" subTitle={`${courseCode} (${courseTitle})`} />

                {selectedTab === 'assignment' && (
                    <FlexDiv className="gap-4">
                        <Button onClick={handleCreate}>
                            <PlusIcon className="mr-[1.15rem]" />
                            Create Assignment
                        </Button>
                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                                Actions
                                <Dropdown className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup
                                open={dropdownOpen}
                                close={() => setDropdownOpen(false)}
                                items={[
                                    {
                                        icon: <SendIcon className="text-[#059669]" />,
                                        name: 'Publish Assignment',
                                        disabled: !selectedItem || selectedItem?.publishStatus === 'Published',
                                        click: () => {
                                            setOpenModal('publish');
                                            setDropdownOpen(false);
                                        },
                                    },
                                    {
                                        icon: <EditIcon />,
                                        name: 'Edit Assignment',
                                        disabled: !selectedItem,
                                        click: () => {
                                            navigate(`/assignments/update/${selectedItem?._id}`, {
                                                state: { assignment: selectedItem, session, course },
                                            });
                                            setDropdownOpen(false);
                                        },
                                    },
                                    {
                                        icon: <DeleteIcon />,
                                        name: 'Delete Assignment',
                                        disabled: !selectedItem,
                                        click: () => {
                                            setOpenModal('delete');
                                            setDropdownOpen(false);
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexDiv>
                )}
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => setSelectedTab('assignment')}>Assignments</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setSelectedTab('draft')}>Draft</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <Assignments
                            toggleRefetch={toggleRefetch}
                            setSelectedItem={setSelectedItem}
                            selectedItem={selectedItem}
                        />
                    </CTabPane>
                    <CTabPane>
                        <Drafts />
                    </CTabPane>
                </CTabContent>
            </CTabs>

            <ConfirmActionDialogue
                input
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete Assignment?"
                subtitle="This Assignment and all attached information will be completely deleted."
                bodyText={`To confirm deletion, enter '${CONFIRM_TEXT}' in the text field.`}
                placeholder={`Input ${CONFIRM_TEXT}`}
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                onChange={handleInputChange}
                value={confirmInputValue}
                isLoading={isLoading}
                btnDisabled={CONFIRM_TEXT !== confirmInputValue.toLowerCase()}
            />
            <ConfirmActionDialogue
                show={openModal === 'publish'}
                close={() => setOpenModal('')}
                title="Publish Assignment?"
                subtitle="This Assignment will be published on the student’s portal.."
                confirmAction={handlePublish}
                btn2Text="Yes, Publish"
                isLoading={isLoading}
                bgColor="#10B981"
                svgIcon={<SendIcon className="text-[#059669]" />}
            />
        </div>
    );
};

export default CourseAssignments;
