import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PaperIcon } from '../../../assets/icons/paper.svg';
import { Button, LoadingButton } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import DatePicker from '../../../components/inputs/date-picker';
import Select from '../../../components/inputs/new-select';
import TextAreaInput from '../../../components/inputs/text-area';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../components/loader';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { createAssignment, editAssignment, getAssignmentDraft } from '../../../redux/assignment/actions';
import { formatTime } from '../../../utils';

import ComposeAssignment from './Composee';
import { ASSIGNMENT_TYPES } from './utils';
import { ValidationSchema } from './ValidationSchema';

const FirstStepBox = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
`;

const GridRows = tw.div`
    grid
    grid-cols-2
    gap-x-[32px]
    gap-y-[24px]
    mb-10
`;

const CreateAssignment = ({ isUpdate }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { session, course, assignment } = state || {};

    const { isLoading } = useSelector((state) => state.assignment);

    const [step, setStep] = useState(1);
    const [storedValues, setStoredValues] = useState({});
    const [draft, setDraft] = useState(null);
    const [isGettingDraft, setIsGettingDraft] = useState(isUpdate ? false : true);

    const defaultValues = isUpdate ? assignment : draft;
    const title = isUpdate ? 'Edit' : 'Create';

    const isDisabled = (values) => {
        const { assignmentType, deadlineTime, deadlineDate, continuousAssessment, totalMarks, title } = values;
        if (!assignmentType || !deadlineTime || !deadlineDate || !continuousAssessment || !totalMarks || !title) {
            return true;
        }
        setStoredValues(values);
        return false;
    };

    useEffect(() => {
        if (isUpdate) return;

        const getDraft = async () => {
            const data = await dispatch(getAssignmentDraft(course._id));
            if (data) {
                setDraft(data.data);
            }
            setIsGettingDraft(false);
        };

        getDraft();
    }, [course._id, dispatch, isUpdate]);

    if (isGettingDraft) return <Loader />;

    return (
        <div className="pb-20">
            <GoBack
                title={`Assignments / ${course?.courseCode} (${course?.courseTitle})`}
                subTitle={`${title} Assignment`}
            />

            <PageTitle align="left" top="3.2rem" bottom="3.2rem">
                {step === 1 ? `${title} Assignment` : 'Compose Assignment'}
            </PageTitle>

            {step === 1 ? (
                <Formik
                    initialValues={{
                        assignmentType: defaultValues?.assignmentType || '',
                        title: defaultValues?.title || '',
                        deadlineDate: defaultValues?.deadlineDate || '',
                        deadlineTime: defaultValues?.deadlineTime || '',
                        totalMarks: defaultValues?.totalMarks || '',
                        continuousAssessment: defaultValues?.continuousAssessment || '',
                        description: defaultValues?.description || '',
                        session,
                        courseId: course._id,
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        if (values.assignmentType === 'Platform Based') return setStep(2);

                        const { session, courseId, ...otherValues } = values;

                        const payload = {
                            ...otherValues,
                            deadlineTime: formatTime(values.deadlineTime),
                            deadlineDate: format(new Date(values.deadlineDate), 'yyyy-MM-dd'),
                        };
                        const res = isUpdate
                            ? await dispatch(editAssignment(assignment._id, payload))
                            : await dispatch(createAssignment(courseId, session, payload));

                        if (res) {
                            navigate(-1);
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldError, setFieldValue, touched }) => (
                        <Form className="max-w-[1000px]">
                            {step === 1 && (
                                <FirstStepBox>
                                    <FlexCentredRow className="gap-2 mb-8">
                                        <PaperIcon />
                                        <Text weight="600" size="1.6rem">
                                            New Assignment
                                        </Text>
                                    </FlexCentredRow>

                                    <GridRows>
                                        <Select
                                            name="assignmentType"
                                            objProp="name"
                                            label="Assignment Type"
                                            placeholder="Type"
                                            onChange={(selected) => {
                                                setFieldValue('assignmentType', selected[0].value);
                                            }}
                                            data={ASSIGNMENT_TYPES}
                                            useComponentState={false}
                                            passedSelectedItems={
                                                values.assignmentType ? [{ name: values.assignmentType }] : null
                                            }
                                            error={errors.assignmentType}
                                        />
                                        <TextInput
                                            label="Title"
                                            name="title"
                                            type="text"
                                            placeholder="Title"
                                            errors={errors}
                                            onChange={handleChange}
                                            value={values.title}
                                        />
                                        <DatePicker
                                            name="deadlineDate"
                                            label="Deadline Date (Optional)"
                                            touched={touched}
                                            initialValue={values.deadlineDate ? new Date(values.deadlineDate) : null}
                                        />
                                        <TextInput
                                            label="Deadline Time (Optional)"
                                            name="deadlineTime"
                                            type="time"
                                            errors={errors}
                                            onChange={handleChange}
                                            value={values.deadlineTime}
                                        />
                                        <Select
                                            name="continuousAssessment"
                                            label="Continous Assessment"
                                            objProp="name"
                                            placeholder="Continous Assessment"
                                            data={['Yes', 'No'].map((item) => ({ name: item }))}
                                            onChange={(selected) => {
                                                setFieldValue('continuousAssessment', selected[0].name);
                                            }}
                                            useComponentState={false}
                                            passedSelectedItems={
                                                values.continuousAssessment
                                                    ? [{ name: values.continuousAssessment }]
                                                    : null
                                            }
                                            error={errors.continuousAssessment}
                                        />

                                        <TextInput
                                            label="Total Marks"
                                            name="totalMarks"
                                            type="number"
                                            placeholder="Total Marks"
                                            errors={errors}
                                            onChange={handleChange}
                                            value={values.totalMarks}
                                        />
                                        <TextAreaInput
                                            name="description"
                                            label="Description"
                                            value={values.description}
                                            errorMessage={errors.description}
                                            placeholder="Description"
                                            height="8rem"
                                            onChange={handleChange}
                                        />
                                    </GridRows>

                                    <FlexRowEnd className="gap-6">
                                        <Button onClick={() => navigate(-1)} border="1px solid #D1D5DB" type="button">
                                            Cancel
                                        </Button>
                                        <LoadingButton disabled={isDisabled(values)} loading={isLoading} type="submit">
                                            {values.assignmentType === 'Platform Based'
                                                ? 'Proceed'
                                                : `${title} Assignment`}
                                        </LoadingButton>
                                    </FlexRowEnd>
                                </FirstStepBox>
                            )}
                        </Form>
                    )}
                </Formik>
            ) : (
                <ComposeAssignment
                    isUpdate={isUpdate}
                    setStep={setStep}
                    stepOneValues={storedValues}
                    initialValues={defaultValues}
                    assignmentId={assignment?._id}
                />
            )}
        </div>
    );
};

export default CreateAssignment;
